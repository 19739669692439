import toast from "react-hot-toast";

export function showToast({ type, message }) {
  if (message?.includes("prisma")) {
    if (message?.includes("Unique constraint failed")) {
      return showToast({ type: "error", message: "Something went wrong" });
    }
    if (message?.includes("Foreign key constraint failed")) {
      return showToast({ type: "error", message: "Something went wrong" });
    }
    return showToast({ type: "error", message: "Something went wrong" });
  }
  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "success":
      toast.success(message);
      break;
    case "warn":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
}
