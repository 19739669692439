import { gql } from "@apollo/client";

export const report_query = gql`
  query getReport {
    get_report: getReport {
      items {
        s_no
        district_name
        total_society_count
        live_society_count
        testing_society_count
        test_verified_status
        not_started_process
        uploaded_with_error_count
        uploaded_without_error_count
        backlog_entry_count
        error {
          status_code
          message
        }
      }
    }
  }
`;
