import { dynamicClear, useDynamicSelector } from "@services/redux";
import { capitalize } from "lodash";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const PushAdditionalDataToBridgeDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const push_additional_data_to_bridge_list = useDynamicSelector(
  //   "pushAdditionalDataToBridge"
  // );

  const additional_excel_log_response_data = useDynamicSelector(
    "getAdditionalExcelLog"
  );

  const [response, set_response] = useState(null);
  useEffect(() => {
    if (additional_excel_log_response_data?.log) {
      const parsedLogs = JSON.parse(additional_excel_log_response_data?.log);
      set_response(parsedLogs);
    }
    return () => {
      dispatch(dynamicClear("getAdditionalExcelLog"));
    };
  }, [additional_excel_log_response_data]);

  return (
    <Box>
      {/* <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          padding: "5px",
          marginBottom: "10px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>
          {push_additional_data_to_bridge_list.error?.message?.length &&
            push_additional_data_to_bridge_list.error?.message}
        </span>
      </Box> */}
      {response && (
        <Box
          style={{
            maxHeight: "380px",
            overflowY: "auto",
          }}
        >
          {response?.map(
            (log, index) =>
              log && (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ marginBottom: "5px" }}>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Table:
                    </span>
                    <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                      {log?.table_name &&
                        capitalize(log.table_name.replace(/_/g, " "))}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Response:
                    </span>
                    <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                      {log?.status}
                    </span>
                    <hr style={{ border: "1px solid lightgray" }} />
                  </div>

                  {log?.status != "Success" && (
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Error:
                      </span>
                      <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                        {log?.error}
                      </span>
                      <hr style={{ border: "1px solid lightgray" }} />
                    </div>
                  )}
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

export default PushAdditionalDataToBridgeDetails;
