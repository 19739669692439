import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  send_live_credential_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";

const SendLiveCredentialsDetail = (props) => {
  const { handle_modal_close } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const [password, set_password] = useState(null);
  const [email, set_email] = useState(null);

  const push_bridge_to_live_response_list = useDynamicSelector(
    "getPushToBridgeLiveResponse"
  );

  const {
    status: send_live_credential_status,
    error: send_live_credential_error,
    loading: send_live_credential_loading,
  } = useDynamicSelector("sendLiveCredential");

  console.log(
    "send_live_credential_loading: ",
    send_live_credential_status,
    send_live_credential_error,
    send_live_credential_loading
  );

  const handle_send_live_credential = () => {
    let keys = [{ key: "sendLiveCredential", loading: true }];
    let variable = {
      society_id: push_bridge_to_live_response_list?.society_id,
      password,
      email,
    };
    dispatch(dynamicRequest(keys, send_live_credential_query, variable));
  };

  useEffect(() => {
    if (send_live_credential_status === "Success") {
      showToast({
        type: "success",
        message: "Live Credential Sent Successfully",
      });
      dispatch(dynamicClear("sendLiveCredential"));
      handle_modal_close(false);
    } else if (send_live_credential_error) {
      showToast({
        type: "error",
        message: send_live_credential_error?.message,
      });
      dispatch(dynamicClear("sendLiveCredential"));
      handle_modal_close(false);
    }

    return () => {
      form.resetFields();
    };
  }, [send_live_credential_status, send_live_credential_error]);

  const handle_password_change = (e) => {
    set_password(e.target.value);
  };

  const handle_email_change = (e) => {
    set_email(e.target.value);
  };

  const handle_finish = () => {
    handle_send_live_credential();
  };
  return (
    <div className="health_check_detail">
      <h3 className="password_header">Send Live Credentials</h3>

      <Form layout="vertical" onFinish={handle_finish} form={form}>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            className="password_input"
            value={password}
            onChange={handle_password_change}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input
            className="email_input"
            value={email}
            onChange={handle_email_change}
            placeholder="Enter your email"
          />
        </Form.Item>

        <Form.Item
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={send_live_credential_loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SendLiveCredentialsDetail;
