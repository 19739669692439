import {
  check_excel_validation_mutation,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import { Box, Center, Divider, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  amountFormat,
  amountFormatWithSymbolAndColor,
} from "@helpers/constants";
import Loader from "react-js-loader";

import html2pdf from "html2pdf.js";
import InvestmentDetails from "./investment_details";
import BorrowingDetails from "./borrowing_details";
import RDLoanDetails from "./rd_loan_details";
import RDDepositDetails from "./rd_deposits_details";
import FDDepositDetails from "./fd_deposit_details";
import FDLoanDetails from "./fd_loan_details";
import AntdModal from "../ui/antd_modal";
import GLMismatchSummary from "./gl_mismatch_summary";
const Reports = (props) => {
  const { t } = useTranslation();
  const { initialValues, close, is_society_report, is_gl_mismatch } = props;
  const [open_gl_mismatch_summary_modal, set_open_gl_mismatch_summary_modal] =
    useState(false);
  const dispatch = useDispatch();
  const {
    society_name,
    society_code,
    contact_person_1,
    contact_person_email,
    share_capital_gl_amount,
    share_capital_match,
    member_share,
    loan_data,
    deposit_data,
    non_member_due_to,
    due_to_gl,
    due_to_gl_match_msg,
    non_member_due_by,
    due_by_gl,
    due_by_gl_match_msg,
    investment_group_array,
    borrowing_group_array,
    member_due_to,
    due_to_total,
    member_due_by,
    due_by_total,
    department_sub_department_array,
    error,
    due_by_gl_names,
    due_to_gl_names,
    loading,
    fd_loan,
    rd_loan,
    jewel_loan,
    rd_deposit,
    fd_deposit,
    staff_loan_data,
  } = useDynamicSelector("demand_generation");

// useEffect(()=>{
//   if(is_gl_mismatch){
//     set_open_gl_mismatch_summary_modal(true)
//   }
// },[is_gl_mismatch])

  useEffect(() => {
    let keys = [{ key: "demand_generation", loading: true }];
    if (is_society_report) {
      dispatch(
        dynamicRequest(keys, check_excel_validation_mutation, {
          excel_id: initialValues?.latest_excel?.id,
        })
      );
    } else {
      dispatch(
        dynamicRequest(keys, check_excel_validation_mutation, {
          excel_id: initialValues?.id,
        })
      );
    }
  }, [initialValues, is_society_report]);

  const pdfContentRef = useRef(null);

  const handleDownloadPDF = () => {
    const element = pdfContentRef.current;

    const opt = {
      margin: 1,
      filename: `${society_name}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };

  const gl_mismatch_summary_click = () => {
    set_open_gl_mismatch_summary_modal(true);
  };

  const open_gl_mismatch_summary_modal_cancel = () => {
    set_open_gl_mismatch_summary_modal(false);
  };

  return (
    <Box>
      {loading ? (
        <Loader
          type="spinner-default"
          bgColor={"#349481"}
          color={"#349481"}
          size={50}
        />
      ) : (
        <>
          {error?.message ? (
            <Center>
              <Text
                fontSize={"16px"}
                bold
                color={"red.500"}
                textAlign={"center"}
              >
                {error?.message}
              </Text>
            </Center>
          ) : (
            <div ref={pdfContentRef}>
              <VStack space={"5"}>
                <Box marginTop={"10px"}>
                  <Text bold fontSize="16px">
                    {society_code} - {society_name}
                  </Text>
                </Box>

                <HStack>
                  <Box flex={1}>
                    <HStack space="10">
                      <Box width={"140px"}>
                        <Text bold>{t("contact_person_name")}</Text>
                      </Box>
                      <Box flex={1}>{contact_person_1}</Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={3}>
                      <Box width={"140px"}>
                        <Text bold>{t("contact_person_email")}</Text>
                      </Box>
                      <Box flex={1}>{contact_person_email}</Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="10">
                      <Box width={"140px"}>
                        <Text bold>{t("share_capital_gl_amount")}</Text>
                      </Box>
                      <Box flex={1}>
                        {amountFormatWithSymbolAndColor(
                          share_capital_gl_amount
                        )}
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={2.5}>
                      <Box width={"140px"}>
                        <Text bold>{t("member_share")}</Text>
                      </Box>
                      <Box flex={1}>
                        {amountFormatWithSymbolAndColor(member_share)}
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="10">
                      <Box width={"140px"}>
                        <Text bold color={"red"}>
                          {t("share_capital_match")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          color={
                            share_capital_match
                              ?.toLowerCase()
                              .includes("mismatched")
                              ? "red.500"
                              : "green.600"
                          }
                        >
                          {share_capital_match}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}></Box>
                </HStack>
                <Box>
                  {share_capital_match
                    ?.toLowerCase()
                    .includes("mismatched") && (
                    <div>
                      The total of respective amounts in{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        "Total Share"{" "}
                      </span>
                      column values in{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        "Member"{" "}
                      </span>
                      sheet must match with{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        "Share Capital - A Class Members"
                      </span>{" "}
                      value in the{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        "GL Account" {""}
                      </span>{" "}
                      sheet{" "}
                    </div>
                  )}
                </Box>

                <Divider />
                <>
                  {" "}
                  <div className="gl-mismatch-btn-container">
                    <Text bold fontSize="16px">
                      {t("loan_outstanding_amount")}
                    </Text>
                    {/* {
                      <Button
                        onClick={gl_mismatch_summary_click}
                        type="primary"
                      >
                        {t("gl_mismatched_summary")}
                      </Button>
                    } */}
                  </div>
                  <table border>
                    <thead>
                      <tr>
                        <th>{t("loan_product")}</th>
                        <th>{t("gl_amount")}</th>
                        <th>{t("breakup_total_amount")}</th>
                        <th>{t("result")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loan_data?.map((x) => {
                        return (
                          <tr>
                            <td>{x.code}</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x.surety_loan_gl_amount
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x.surety_loan_outstanding_sum
                              )}
                            </td>
                            <td>
                              <Text
                                color={
                                  x.surety_loan_gl_amount_match
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x.surety_loan_gl_amount_match}
                              </Text>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Box>
                    {loan_data?.map((x) => {
                      if (
                        x.surety_loan_gl_amount_match
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <Text>
                            The total of respective amounts in{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "Outstanding Loan Amount (in Rs.)"
                            </span>{" "}
                            column in the{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.code}"
                            </span>{" "}
                            sheet must match with{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.principal_payment_gl_name}"
                            </span>{" "}
                            value in the{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "GL Account"
                            </span>{" "}
                            sheet{" "}
                          </Text>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {loan_data?.some(
                      (x) =>
                        x.principal_payment_gl_required_type ||
                        x.interest_payment_gl_required_type ||
                        x.penal_interest_gl_required_type
                    ) && (
                      <table bordered>
                        <thead>
                          <tr>
                            <th>{t("gl_for")}</th>
                            <th>{t("expected_gl_type")}</th>
                            <th>{t("given_gl_type")}</th>
                            <th>{t("result")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loan_data?.map((x, index) => {
                            if (
                              x.principal_payment_gl_required_type ||
                              x.interest_payment_gl_required_type ||
                              x.penal_interest_gl_required_type
                            ) {
                              return (
                                <>
                                  {x.principal_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Principal"}</td>

                                      <td>
                                        {x.principal_payment_gl_required_type}
                                      </td>
                                      <td>{x.principal_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.principal_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.principal_payment_gl_match_msg}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                  {x.interest_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Interest"}</td>

                                      <td>
                                        {x.interest_payment_gl_required_type}
                                      </td>
                                      <td>{x.interest_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.penal_interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {
                                            x.penal_interest_payment_gl_match_msg
                                          }
                                        </Text>
                                      </td>
                                    </tr>
                                  )}

                                  {x.penal_interest_gl_required_type && (
                                    <tr>
                                      <td>{"Penal"}</td>

                                      <td>
                                        {x.penal_interest_gl_required_type}
                                      </td>
                                      <td>
                                        {x.penal_interest_payment_gl_type}
                                      </td>
                                      <td>
                                        <Text
                                          color={
                                            x.penal_interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {
                                            x.penal_interest_payment_gl_match_msg
                                          }
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    )}
                  </Box>
                  <Box>
                    {loan_data?.map((x) => {
                      if (
                        x.principal_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {loan_data?.map((x) => {
                      if (
                        x.penal_interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Penal Interest"
                        </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {loan_data?.map((x) => {
                      if (
                        x.interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Interest"
                        </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                "{x.interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Loan Products"{" "}
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Divider />
                </>
                <>
                  <Box marginTop={"10px"}>
                    <Text bold fontSize="16px">
                      {t("deposits_received")}
                    </Text>
                  </Box>
                  <table border>
                    <thead>
                      <tr>
                        <th>{t("loan_product")}</th>
                        <th>{t("gl_amount")}</th>
                        <th>{t("breakup_total_amount")}</th>
                        <th>{t("result")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deposit_data?.map((x) => {
                        return (
                          <tr>
                            <td>{x.code}</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x.deposit_gl_amount
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x.deposit_outstanding_sum
                              )}
                            </td>
                            <td>
                              <Text
                                color={
                                  x.amount_match_msg
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x.amount_match_msg}
                              </Text>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Box>
                    {deposit_data?.map((x) => {
                      if (
                        x.amount_match_msg?.toLowerCase().includes("mismatched")
                      ) {
                        return (
                          <Text>
                            The total of respective amounts in{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "Accumulated Amount"
                            </span>{" "}
                            column in the{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.code}"
                            </span>{" "}
                            sheet must match with {""}{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.deposit_gl_name}" {""}
                            </span>
                            value in the{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "GL Account" {""}
                            </span>{" "}
                            sheet
                          </Text>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {deposit_data?.some(
                      (x) =>
                        x.principal_payment_gl_required_type ||
                        x.interest_payment_gl_required_type
                    ) && (
                      <table bordered>
                        <thead>
                          <tr>
                            <th>{t("gl_for")}</th>
                            <th>{t("expected_gl_type")}</th>
                            <th>{t("given_gl_type")}</th>
                            <th>{t("result")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deposit_data.map((x, index) => {
                            if (
                              x.principal_payment_gl_required_type ||
                              x.interest_payment_gl_required_type
                            ) {
                              return (
                                <React.Fragment key={index}>
                                  {x.principal_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Principal"}</td>
                                      <td>
                                        {x.principal_payment_gl_required_type}
                                      </td>
                                      <td>{x.principal_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.principal_payment_gl_type_match
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.principal_payment_gl_type_match}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                  {x.interest_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Interest"}</td>
                                      <td>
                                        {x.interest_payment_gl_required_type}
                                      </td>
                                      <td>{x.interest_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.interest_payment_gl_type_match
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.interest_payment_gl_type_match}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    )}
                  </Box>
                  <Box>
                    {deposit_data?.map((x) => {
                      if (
                        x.principal_payment_gl_type_match
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Interest"
                        </span>{" "} */}{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "} */}
                              GL Account
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                "{x.interest_payment_gl_type}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Deposit Products"{" "}
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_name}"
                              </span>
                            </Text>
                            {/* <Text>
                        The total amount of{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Principal"
                        </span>{" "}
                        in the must match with the amount in the GL Account
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "{x.principal_payment_gl_name}"
                        </span>
                      </Text> */}
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {deposit_data?.map((x) => {
                      if (
                        x.interest_payment_gl_type_match
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "} */}
                              GL Account
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                "{x.interest_payment_gl_type}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Deposit Products"{" "}
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_name}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Divider />
                </>
                <>
                  <Box marginTop={"10px"}>
                    <Text bold fontSize="16px">
                      Sundry
                    </Text>
                  </Box>

                  <table bordered>
                    <thead>
                      <tr>
                        <th>{t("item")}</th>
                        <th>{t("gl_amount")}</th>
                        <th>{t("member_breakup_total")}</th>
                        <th>{t("non_member_breakup_total")}</th>
                        <th>{t("total")}</th>
                        <th>{t("result")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{"Sundry Creditors"}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(Number(due_by_gl))}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(member_due_by)}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(non_member_due_by)}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(due_by_total)}
                        </td>
                        <td>
                          <Text
                            color={
                              due_by_gl_match_msg
                                ?.toLowerCase()
                                .includes("mismatched")
                                ? "red.500"
                                : "green.600"
                            }
                          >
                            {due_by_gl_match_msg}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td>{"Sundry Debtors"}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(Number(due_to_gl))}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(
                            Number(member_due_to)
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(
                            Number(non_member_due_to)
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormatWithSymbolAndColor(due_to_total)}
                        </td>
                        <td>
                          <Text
                            color={
                              due_to_gl_match_msg
                                ?.toLowerCase()
                                .includes("mismatched")
                                ? "red.500"
                                : "green.600"
                            }
                          >
                            {due_to_gl_match_msg}
                          </Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Box>
                    {due_by_gl_match_msg
                      ?.toLowerCase()
                      .includes("mismatched") ? (
                      <Text>
                        The total of respective amounts in{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Due By"
                        </span>{" "}
                        column in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Member"{" "}
                        </span>
                        sheet and the total of respective amounts in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          "amount"
                        </span>{" "}
                        column in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Non-Member Due By"
                        </span>{" "}
                        sheet should match {""}{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "{due_by_gl_names}"
                        </span>{" "}
                        value in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "}
                        sheet
                      </Text>
                    ) : null}
                  </Box>
                  <Box>
                    {due_to_gl_match_msg
                      ?.toLowerCase()
                      .includes("mismatched") ? (
                      <Text>
                        The total of respective amounts in{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Due To"
                        </span>{" "}
                        column in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Member"{" "}
                        </span>
                        sheet and the total of respective amounts in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          "amount"
                        </span>{" "}
                        column in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Non-Member Due To"
                        </span>{" "}
                        sheet should match {""}{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "{due_to_gl_names}"
                        </span>{" "}
                        value in the{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "}
                        sheet
                      </Text>
                    ) : null}
                  </Box>
                  <Divider />
                </>

                <InvestmentDetails
                  investment_group_array={investment_group_array}
                />
                <BorrowingDetails
                  borrowing_group_array={borrowing_group_array}
                />
                <>
                  <Box marginTop={"10px"}>
                    <Text bold fontSize="16px">
                      Staff Loan Outstanding
                    </Text>
                  </Box>
                  <table border>
                    <thead>
                      <tr>
                        <th>{t("loan_product")}</th>
                        <th>{t("gl_amount")}</th>
                        <th>{t("breakup_total_amount")}</th>
                        <th>{t("result")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staff_loan_data?.map((x) => {
                        return (
                          <tr>
                            <td>{x.code}</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x?.staff_loan_gl_amount
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x?.staff_loan_outstanding_sum
                              )}
                            </td>
                            <td>
                              <Text
                                color={
                                  x?.staff_loan_gl_amount_match
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x?.staff_loan_gl_amount_match}
                              </Text>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Box>
                    {staff_loan_data?.map((x) => {
                      if (
                        x.staff_loan_gl_amount_match
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <Text>
                            The total of respective amounts in{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              " Outstanding Staff Loan Amount (in Rs.)"
                            </span>{" "}
                            column in the{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.code}"
                            </span>{" "}
                            sheet must match with{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.principal_payment_gl_name}"
                            </span>{" "}
                            value in the{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "GL Account"
                            </span>{" "}
                            sheet{" "}
                          </Text>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {staff_loan_data?.some(
                      (x) =>
                        x.principal_payment_gl_required_type ||
                        x.interest_payment_gl_required_type ||
                        x.penal_interest_gl_required_type
                    ) && (
                      <table bordered>
                        <thead>
                          <tr>
                            <th>{t("loan_product")}</th>
                            <th>{t("gl_for")}</th>
                            <th>{t("expected_gl_type")}</th>
                            <th>{t("given_gl_type")}</th>
                            <th>{t("result")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {staff_loan_data?.map((x, index) => {
                            if (
                              x.principal_payment_gl_required_type ||
                              x.interest_payment_gl_required_type ||
                              x.penal_interest_gl_required_type
                            ) {
                              return (
                                <>
                                  {x.principal_payment_gl_required_type && (
                                    <tr>
                                      <td>{x.code}</td>
                                      <td>{"Principal"}</td>

                                      <td>
                                        {x.principal_payment_gl_required_type}
                                      </td>
                                      <td>{x.principal_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.principal_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.principal_payment_gl_match_msg}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                  {x.interest_payment_gl_required_type && (
                                    <tr>
                                      <td>{x.code}</td>
                                      <td>{"Interest"}</td>

                                      <td>
                                        {x.interest_payment_gl_required_type}
                                      </td>
                                      <td>{x.interest_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.interest_payment_gl_match_msg}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}

                                  {x.penal_interest_gl_required_type && (
                                    <tr>
                                      <td>{x.code}</td>
                                      <td>{"Penal"}</td>

                                      <td>
                                        {x.penal_interest_gl_required_type}
                                      </td>
                                      <td>
                                        {x.penal_interest_payment_gl_type}
                                      </td>
                                      <td>
                                        <Text
                                          color={
                                            x.penal_interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {
                                            x.penal_interest_payment_gl_match_msg
                                          }
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    )}
                  </Box>
                  <Box>
                    {staff_loan_data?.map((x) => {
                      if (
                        x.principal_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "GL Account" {""}
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Staff Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {staff_loan_data?.map((x) => {
                      if (
                        x.penal_interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "Penal Interest"
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Staff Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>

                  <Box>
                    {staff_loan_data?.map((x) => {
                      if (
                        x.interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "Interest"
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                "{x.interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Staff Loan Products"{" "}
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                </>
                <Divider />
                <>
                  <Box marginTop={"10px"}>
                    <Text bold fontSize="16px">
                      Jewel Loan Outstanding
                    </Text>
                  </Box>
                  <table border>
                    <thead>
                      <tr>
                        <th>{t("loan_product")}</th>
                        <th>{t("gl_amount")}</th>
                        <th>{t("breakup_total_amount")}</th>
                        <th>{t("result")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jewel_loan?.map((x) => {
                        return (
                          <tr>
                            <td>{x.code}</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x?.loan_gl_amount
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormatWithSymbolAndColor(
                                x?.loan_outstanding_sum
                              )}
                            </td>
                            <td>
                              <Text
                                color={
                                  x?.loan_gl_amount_match
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x?.loan_gl_amount_match}
                              </Text>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Box>
                    {jewel_loan?.map((x) => {
                      if (
                        x.staff_loan_gl_amount_match
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <Text>
                            The total of respective amounts in{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "Outstanding Jewel Loan Amount (in Rs.)"
                            </span>{" "}
                            column in the{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.code}"
                            </span>{" "}
                            sheet must match with{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              "{x.principal_payment_gl_name}"
                            </span>{" "}
                            value in the{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "GL Account"
                            </span>{" "}
                            sheet{" "}
                          </Text>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {jewel_loan?.some(
                      (x) =>
                        x.principal_payment_gl_required_type ||
                        x.interest_payment_gl_required_type ||
                        x.penal_interest_gl_required_type
                    ) && (
                      <table bordered>
                        <thead>
                          <tr>
                            <th>{t("gl_for")}</th>
                            <th>{t("expected_gl_type")}</th>
                            <th>{t("given_gl_type")}</th>
                            <th>{t("result")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jewel_loan?.map((x, index) => {
                            if (
                              x.principal_payment_gl_required_type ||
                              x.interest_payment_gl_required_type ||
                              x.penal_interest_gl_required_type
                            ) {
                              return (
                                <>
                                  {x.principal_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Principal"}</td>

                                      <td>
                                        {x.principal_payment_gl_required_type}
                                      </td>
                                      <td>{x.principal_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.principal_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.principal_payment_gl_match_msg}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                  {x.interest_payment_gl_required_type && (
                                    <tr>
                                      <td>{"Interest"}</td>

                                      <td>
                                        {x.interest_payment_gl_required_type}
                                      </td>
                                      <td>{x.interest_payment_gl_type}</td>
                                      <td>
                                        <Text
                                          color={
                                            x.interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {x.interest_payment_gl_match_msg}
                                        </Text>
                                      </td>
                                    </tr>
                                  )}

                                  {x.penal_interest_gl_required_type && (
                                    <tr>
                                      <td>{"Penal"}</td>

                                      <td>
                                        {x.penal_interest_gl_required_type}
                                      </td>
                                      <td>
                                        {x.penal_interest_payment_gl_type}
                                      </td>
                                      <td>
                                        <Text
                                          color={
                                            x.penal_interest_payment_gl_match_msg
                                              ?.toLowerCase()
                                              .includes("mismatched")
                                              ? "red.500"
                                              : "green.600"
                                          }
                                        >
                                          {
                                            x.penal_interest_payment_gl_match_msg
                                          }
                                        </Text>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    )}
                  </Box>
                  <Box>
                    {jewel_loan?.map((x) => {
                      if (
                        x.principal_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "GL Account" {""}
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Jewel Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.principal_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Box>
                    {jewel_loan?.map((x) => {
                      if (
                        x.penal_interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "Penal Interest"
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Jewel Loan Products"
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.penal_interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>

                  <Box>
                    {jewel_loan?.map((x) => {
                      if (
                        x.interest_payment_gl_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                      ) {
                        return (
                          <>
                            <Text>
                              Expected type of{" "}
                              {/* <span
        style={{
          fontWeight: "bold",
        }}
      >
        "Interest"
      </span>{" "} */}
                              GL Account{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                "{x.interest_payment_gl_name}"
                              </span>{" "}
                              in the{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "Jewel Loan Products"{" "}
                              </span>{" "}
                              sheet should be{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_required_type}"
                              </span>{" "}
                              but found{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                "{x.interest_payment_gl_type}"
                              </span>
                            </Text>
                          </>
                        );
                      }
                      return null;
                    })}
                  </Box>
                  <Divider />
                </>
                <FDDepositDetails fd_deposit={fd_deposit} />
                <FDLoanDetails fd_loan={fd_loan} />
                <RDDepositDetails rd_deposit={rd_deposit} />
                <RDLoanDetails rd_loan={rd_loan} />

                <>
                  <Box marginTop={"10px"}>
                    <Text bold fontSize="16px">
                      {t("departments")}
                    </Text>
                  </Box>
                  <table border>
                    <thead>
                      <tr>
                        <th>{t("departments")}</th>
                        <th>{t("sub_departments")}</th>
                        <th>{t("no_of_sub_department")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {department_sub_department_array?.map((x) => {
                        return (
                          <tr>
                            <td>{x.department}</td>
                            <td>
                              {x.sub_department?.map((y, i) => {
                                return (
                                  <>
                                    {i ? ", " : " "}
                                    {y}
                                  </>
                                );
                              })}
                            </td>
                            <td>{x?.sub_department?.length}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              </VStack>
            </div>
          )}
        </>
      )}

      <HStack marginTop={"20px"} justifyContent={"flex-end"} space={"10"}>
        {!error?.message ? (
          <Button
            onClick={() => {
              handleDownloadPDF();
            }}
            type="primary"
          >
            {t("Download")}
          </Button>
        ) : (
          ""
        )}
        <Button onClick={close} danger>
          {t("close")}
        </Button>
      </HStack>
      {/* <AntdModal
        isOpen={open_gl_mismatch_summary_modal}
        onClose={open_gl_mismatch_summary_modal_cancel}
        width={1000}
        footer={null}
        header={t("gl_mismatched_summary")}
        component={
          <GLMismatchSummary close={open_gl_mismatch_summary_modal_cancel} />
        }
      /> */}
    </Box>
  );
};

export default Reports;
