import {
  bridge_mutation,
  calculate_interest_mutation,
  delete_one_society_from_bridge_mutation,
  delete_one_society_from_CBS_mutation,
  dynamicClear,
  dynamicRequest,
  get_CBS_live_migration_response_logs_query,
  get_CBS_migration_logs_query,
  get_live_credential_query,
  get_one_society_query,
  get_push_to_bridge_live_response_query,
  get_push_to_bridge_response_query,
  health_check_mutation,
  push_additional_data_to_bridge,
  push_to_bridge_live_mutation,
  push_to_CBS_live_mutation,
  push_to_cbs_mutation,
  query_get_additional_sheet_logs,
  send_live_credential_query,
  useDynamicSelector,
  user_excel_query,
} from "@services/redux";
import { Button, Card } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import { retrieveItem } from "@helpers/storage";
import toast from "react-hot-toast";
import ResponseBridgeStatus from "./response_bridge_status";
import AntdModal from "@views/components/ui/antd_modal";
import BridgePushResponseDetails from "./bridge_push_response_details";
import CbsPushResponseDetails from "./cbs_push_response_details";
import PushBridgeToLiveResponseDetails from "./push_bridge_to_live_response_details";
import PushCBStoLiveResponseDetails from "./push_cbs_to_live_response_details";
import CredentialsDetails from "./credentials_details";
import BridgeLiveStatus from "./bridge_live_status";
import PushAdditionalDataToBridgeDetails from "./push_additional_data_to_bridge";
import HealthCheckAndInterestModalDetail from "./health_check_and_interest_modal_detail";
import SendLiveCredentialsDetail from "./send_live_credentials";
import ShowVerificationCertificate from "./show_verification_certificate";

const ResponseDetails = (props) => {
  const role = retrieveItem("role");
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, society_id } = useParams();
  const [errors, setErrors] = useState([]);
  const [error_count, setErrorCount] = useState(0);
  const [status, setStatus] = useState("");

  const status_ref = useRef();

  const [bridge_success_modal_open, set_bridge_success_modal_open] =
    useState(false);
  const [bridge_live_success_modal_open, set_bridge_live_success_modal_open] =
    useState(false);

  const [health_check_modal_open, set_health_check_modal_open] =
    useState(false);

  const [interest_calculate_modal_open, set_interest_calculate_modal_open] =
    useState(false);

  const [bridge_response_log_modal_open, set_bridge_response_log_modal_open] =
    useState(false);

  const [CBS_response_log_modal_open, set_CBS_response_log_modal_open] =
    useState(false);

  const [
    bridge_to_live_response_log_modal_open,
    set_bridge_to_live_response_log_modal_open,
  ] = useState(false);
  const [send_live_credential_modal_open, set_send_live_credential_modal_open] =
    useState(false);

  const [
    push_additional_data_to_bridge_modal_open,
    set_push_additional_data_to_bridge_modal_open,
  ] = useState(false);

  const [
    push_cbs_live_success_modal_open,
    set_push_cbs_love_success_modal_open,
  ] = useState(false);

  const [credential_modal_open, set_credential_modal_open] = useState(false);
  const credentials_user_details = useDynamicSelector("getLiveCredential");

  const additional_excel_log_response_data = useDynamicSelector(
    "getAdditionalExcelLog"
  );

  const data = useDynamicSelector("user_excel");

  const {
    status: push_bridge_status,
    error: push_bridge_error,
    loading,
  } = useDynamicSelector("pushToBridge");

  const {
    status: push_cbs_status,
    error: push_cbs_error,
    loading: cbs_loading,
  } = useDynamicSelector("pushToCBS");

  const {
    status: push_to_bridge_live_status,
    error: push_to_bridge_live_error,
    loading: push_to_bridge_live_loading,
  } = useDynamicSelector("pushToBridgeLive");

  const {
    status: push_additional_data_to_bridge_status,
    error: push_additional_data_to_bridge_error,
    loading: push_additional_data_to_bridge_loading,
  } = useDynamicSelector("pushAdditionalDataToBridge");

  const { name, username } = useDynamicSelector("getSociety");
  const response_list = useDynamicSelector("getPushToBridgeResponse");
  const { loading: get_bridge_response_loading } = useDynamicSelector(
    "getPushToBridgeResponse"
  );
  const CBS_response_list = useDynamicSelector("getCBSMigrationLogs");
  const { loading: CBS_response_list_loading } = useDynamicSelector(
    "getCBSMigrationLogs"
  );
  const push_bridge_to_live_response_list = useDynamicSelector(
    "getPushToBridgeLiveResponse"
  );

  const { loading: push_bridge_to_live_response_list_loading } =
    useDynamicSelector("getPushToBridgeLiveResponse");

  const push_cbs_to_live_response_list = useDynamicSelector(
    "getCBSLiveMigrationLogs"
  );

  const { loading: push_cbs_to_live_response_list_loading } =
    useDynamicSelector("getCBSLiveMigrationLogs");

  const {
    status: push_to_cbs_live_status,
    error: push_to_cbs_live_error,
    loading: push_to_cbs_live_loading,
  } = useDynamicSelector("pushToCBSLive");

  const {
    status: delete_bridge_status,
    error: delete_bridge_error,
    loading: delete_bridge_loading,
  } = useDynamicSelector("deleteOneSocietyFromBridge");

  const { status: health_check_status } = useDynamicSelector("healthCheck");
  const { is_health_checked,is_interests_calculated} = useDynamicSelector("getSociety");

  const {
    status: delete_CBS_status,
    error: delete_CBS_error,
    loading: delete_CBS_loading,
  } = useDynamicSelector("deleteOneSocietyDataFromCBS");

  useEffect(() => {
    if (delete_bridge_status === "Success") {
      toast.success("Delete Bridge Successfully ");
      getUserExcel(id);
      get_push_to_bridge_response_list();
      get_CBS_migration_logs_list(id);
    } else if (delete_bridge_error) {
      toast.error(delete_bridge_error?.message);
    }
    dispatch(dynamicClear("deleteOneSocietyFromBridge"));
  }, [delete_bridge_status, delete_bridge_error]);

  useEffect(() => {
    if (delete_CBS_status === "Success") {
      toast.success("Delete CBS Successfully ");
      getUserExcel(id);
      get_push_to_bridge_response_list();
      get_CBS_migration_logs_list(id);
    } else if (delete_CBS_error) {
      toast.error(delete_CBS_error?.message);
    }
    dispatch(dynamicClear("deleteOneSocietyDataFromCBS"));
  }, [delete_CBS_status, delete_CBS_error]);

  useEffect(() => {
    getUserExcel(id);
  }, [id]);

  useEffect(() => {
    if (push_bridge_status === "Success") {
      set_bridge_success_modal_open(true);
    } else if (push_bridge_error) {
      toast.error(push_bridge_error?.message);
    }
    dispatch(dynamicClear("pushToBridge"));
  }, [push_bridge_status, push_bridge_error]);

  useEffect(() => {
    get_additional_excel_log_response_data();
  }, [push_additional_data_to_bridge_status]);

  useEffect(() => {
    if (push_additional_data_to_bridge_error) {
      toast.error(push_additional_data_to_bridge_error?.message);
    }
  }, [push_additional_data_to_bridge_error]);

  useEffect(() => {
    if (
      additional_excel_log_response_data?.log &&
      status_ref.current === "clicked"
    ) {
      set_push_additional_data_to_bridge_modal_open(true);
    }
  }, [additional_excel_log_response_data]);

  useEffect(() => {
    if (push_cbs_status === "Success") {
      set_bridge_success_modal_open(true);
    } else if (push_cbs_error) {
      toast.error(push_cbs_error?.message);
    }
    dispatch(dynamicClear("pushToCBS"));
  }, [push_cbs_status, push_cbs_error]);

  useEffect(() => {
    if (push_to_bridge_live_status === "Success") {
      set_bridge_live_success_modal_open(true);
    } else if (push_to_bridge_live_error) {
      toast.error(push_to_bridge_live_error?.message);
    }
    dispatch(dynamicClear("pushToBridgeLive"));
  }, [push_to_bridge_live_status, push_to_bridge_live_error]);

  useEffect(() => {
    if (push_to_cbs_live_status === "Success") {
      set_bridge_live_success_modal_open(true);
    } else if (push_to_cbs_live_error) {
      toast.error(push_to_cbs_live_error?.message);
    }
    dispatch(dynamicClear("pushToCBSLive"));
  }, [push_to_cbs_live_status, push_to_cbs_live_error]);

  useEffect(() => {
    if (id) {
      get_push_to_bridge_response_list();
      get_CBS_migration_logs_list(id);
      get_push_to_bridge_live_response_list();
      get_CBS_live_migration_response_logs_list(id);
    }
  }, [id]);

  useEffect(() => {
    getSociety();
  }, [society_id]);

  const get_additional_excel_log_response_data = async () => {
    let key = [{ key: "getAdditionalExcelLog", loading: true }];
    let variables = {
      user_excel_id: id,
    };
    await dispatch(
      dynamicRequest(key, query_get_additional_sheet_logs, variables)
    );
  };

  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: society_id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };

  useEffect(() => {
    if (data?.id) {
      const getStatus = (data) => {
        if (
          data?.is_sheet_processing ||
          data?.is_column_processing ||
          data?.is_data_processing
        ) {
          return "Processing";
        }
        if (data?.is_sheet_valid && data?.is_column_valid && data?.is_valid) {
          if (
            data?.is_second_level_validated &&
            data?.secondary_level_validated
          ) {
            return "Valid File";
          }
          if (data?.secondary_level_validated && !data?.third_level_validated) {
            return "GL Mismatch";
          }
          if (!data?.secondary_level_validated) {
            return "Additional Data Error";
          }
        }
        if (data?.is_sheet_valid && data?.is_column_valid && !data?.is_valid) {
          return "Data Error";
        }
        if (data?.is_sheet_valid && !data?.is_column_valid && !data?.is_valid) {
          return "Column Error";
        }
        if (
          !data?.is_sheet_valid &&
          !data?.is_column_valid &&
          !data?.is_valid
        ) {
          return "Sheet Error";
        }
        return "Processing";
      };

      const status = getStatus(data);
      setStatus(status);

      if (data.errors) {
        const temp = JSON.parse(data.errors);
        if (Array.isArray(temp)) {
          setErrorCount(temp.length);

          if (status === "Data Error") {
            const groupedData = temp.reduce(
              (acc, { sheet_name, row_number, ...entry }) => {
                acc[sheet_name] = acc[sheet_name] || {};
                acc[sheet_name][row_number] = acc[sheet_name][row_number] || [];
                acc[sheet_name][row_number].push(entry);
                return acc;
              },
              {}
            );
            setErrors(groupedData);
          } else if (status === "Column Error") {
            const groupedData = temp.reduce((acc, { sheet_name, ...entry }) => {
              acc[sheet_name] = acc[sheet_name] || [];
              acc[sheet_name].push(entry);
              return acc;
            }, {});
            setErrors(groupedData);
          } else if (status === "Sheet Error") {
            setErrors(temp);
          }
        } else {
          setErrorCount(0);
          setErrors([]);
        }
      } else {
        setErrorCount(0);
        setErrors([]);
      }
    }
  }, [data]);

  useEffect(() => {
    get_additional_excel_log_response_data();
  }, []);

  const getUserExcel = (id) => {
    let user_excel_key = [{ key: "user_excel", loading: true }];
    let user_excel_variables = {
      id,
    };
    dispatch(
      dynamicRequest(user_excel_key, user_excel_query, user_excel_variables)
    );
  };

  const get_CBS_migration_logs_list = (id) => {
    let user_excel_key = [{ key: "getCBSMigrationLogs", loading: true }];
    let user_excel_variables = {
      id,
    };
    dispatch(
      dynamicRequest(
        user_excel_key,
        get_CBS_migration_logs_query,
        user_excel_variables
      )
    );
  };

  const handleBridgePush = () => {
    let bridge_key = [{ key: "pushToBridge", loading: true }];
    let bridge_variables = {
      id,
    };
    dispatch(
      dynamicRequest(bridge_key, bridge_mutation, bridge_variables, "M")
    );
  };

  const pushToBridgeLive = () => {
    let keys = [{ key: "pushToBridgeLive", loading: true }];
    let variable = {
      id,
    };
    dispatch(dynamicRequest(keys, push_to_bridge_live_mutation, variable));
  };

  const pushAdditionalDataToBridge = (db_type) => {
    status_ref.current = "clicked";
    if (
      db_type === "Live" &&
      additional_excel_log_response_data?.status === "Success"
    ) {
      return get_additional_excel_log_response_data();
    }

    let keys = [{ key: "pushAdditionalDataToBridge", loading: true }];
    let variable = {
      user_excel_id: id,
      db: db_type,
    };
    dispatch(dynamicRequest(keys, push_additional_data_to_bridge, variable));
    status_ref.current = "";
  };

  const PushToCBSLive = () => {
    let keys = [{ key: "pushToCBSLive", loading: true }];
    let variable = {
      id,
    };
    dispatch(dynamicRequest(keys, push_to_CBS_live_mutation, variable));
  };

  const get_push_to_bridge_response_list = () => {
    let push_bridge_response_key = [
      { key: "getPushToBridgeResponse", loading: true },
    ];
    let push_bridge_response_variables = {
      id,
    };
    dispatch(
      dynamicRequest(
        push_bridge_response_key,
        get_push_to_bridge_response_query,
        push_bridge_response_variables
      )
    );
  };

  const get_push_to_bridge_live_response_list = () => {
    let push_bridge_response_key = [
      { key: "getPushToBridgeLiveResponse", loading: true },
    ];
    let variable = {
      id,
    };
    dispatch(
      dynamicRequest(
        push_bridge_response_key,
        get_push_to_bridge_live_response_query,
        variable
      )
    );
  };

  const get_CBS_live_migration_response_logs_list = (id) => {
    let push_bridge_response_key = [
      { key: "getCBSLiveMigrationLogs", loading: true },
    ];
    let variable = {
      id,
    };
    dispatch(
      dynamicRequest(
        push_bridge_response_key,
        get_CBS_live_migration_response_logs_query,
        variable
      )
    );
  };

  const delete_one_society_from_bridge = () => {
    let delete_bridge_key = [
      { key: "deleteOneSocietyFromBridge", loading: true },
    ];
    let delete_bridge_variables = {
      id,
    };
    dispatch(
      dynamicRequest(
        delete_bridge_key,
        delete_one_society_from_bridge_mutation,
        delete_bridge_variables
      )
    );
  };

  const delete_one_society_data_fromCBS = (id) => {
    let delete_CBS_key = [
      { key: "deleteOneSocietyDataFromCBS", loading: true },
    ];
    let delete_CBS_variables = {
      id,
    };
    dispatch(
      dynamicRequest(
        delete_CBS_key,
        delete_one_society_from_CBS_mutation,
        delete_CBS_variables
      )
    );
  };

  const handleCBSPush = () => {
    let cbs_key = [{ key: "pushToCBS", loading: true }];
    let cbs_variables = {
      id: id,
    };
    dispatch(dynamicRequest(cbs_key, push_to_cbs_mutation, cbs_variables, "M"));
  };

  const handle_credential_details = () => {
    set_credential_modal_open(true);
    get_live_credential_details();
  };

  const get_live_credential_details = () => {
    let keys = [{ key: "getLiveCredential", loading: true }];
    let variable = {
      society_id: push_cbs_to_live_response_list?.society_id,
    };
    dispatch(dynamicRequest(keys, get_live_credential_query, variable));
  };

  const handleBridgeModalClose = () => {
    set_bridge_success_modal_open(false);
  };
  const handleBridgeLiveModalClose = () => {
    set_bridge_live_success_modal_open(false);
  };

  const handleBridgeResponseModalClose = () => {
    set_bridge_response_log_modal_open(false);
  };

  const handleCBSResponseOnClose = () => {
    set_CBS_response_log_modal_open(false);
  };
  const handleBRidgeToLiveResponseOnClose = () => {
    set_bridge_to_live_response_log_modal_open(false);
  };
  const handleCBSToLiveResponseOnClose = () => {
    set_push_cbs_love_success_modal_open(false);
  };

  const handleCBSCheckStatus = () => {
    if (id) {
      get_CBS_migration_logs_list(id);
    }
    set_CBS_response_log_modal_open(true);
  };

  const handleCBStoLiveCheckStatus = () => {
    if (id) {
      get_CBS_live_migration_response_logs_list(id);
    }
    set_push_cbs_love_success_modal_open(true);
  };

  const handleCheckStatus = () => {
    if (id) {
      get_push_to_bridge_response_list();
    }
    set_bridge_response_log_modal_open(true);
  };

  const handleBridgeToPUshCheckLiveStatus = () => {
    if (id) {
      get_push_to_bridge_live_response_list();
    }
    set_bridge_to_live_response_log_modal_open(true);
  };

  const handleModalClose = () => {
    set_bridge_success_modal_open(false);
    getUserExcel(id);
    getSociety();
    get_push_to_bridge_response_list();
    get_CBS_migration_logs_list(id);
  };

  const handleLiveModalClose = () => {
    set_bridge_live_success_modal_open(false);
    getUserExcel(id);
    getSociety();
    get_push_to_bridge_live_response_list();
    get_CBS_live_migration_response_logs_list(id);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleDeleteBridge = () => {
    delete_one_society_from_bridge();
  };
  const handleDeleteCBS = () => {
    delete_one_society_data_fromCBS(id);
  };

  const handleCredentialsModalClose = () => {
    set_credential_modal_open(false);
  };

  const handle_health_check_interest = (type) => {
    if (type === "health_check") {
      set_health_check_modal_open(true);
    } else if (type === "calculate_interest") {
      set_interest_calculate_modal_open(true);
    }
  };

  return (
    <Box flex={1} padding="5">
      {data.loading ? (
        <Loader
          type="spinner-default"
          bgColor={"#349481"}
          color={"#349481"}
          size={50}
        />
      ) : (
        <Box>
          <HStack justifyContent="flex-end">
            <Button type="default" danger onClick={handleBack}>
              Back
            </Button>
          </HStack>
          {role !== "Society" && (
            <Box top="10px" zIndex="2" mb="10px">
              <Text fontSize="md" fontWeight="bold">
                {name} - {username}
              </Text>
            </Box>
          )}
          <Text bold fontSize={"md"}>
            Response
          </Text>
          <Box marginTop="2">
            <Card
              style={{
                alignItems: "center",
              }}
            >
              <HStack flex="1">
                <Box flex="1">
                  <VStack space={"5"}>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:uploaded_date_time")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {data.uploaded_time
                            ? moment(new Date(Number(data.uploaded_time)))
                                .utcOffset("+05:30")
                                .format("DD/MM/YY hh:mm:ss a")
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:status")} : </Text>
                      </Box>
                      <Box>
                        <Text>{status} </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:error_count")} : </Text>
                      </Box>
                      <Box>
                        <Text>{error_count} </Text>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
                <Box flex="1">
                  <Center my={"auto"}>
                    {status === "Valid File" &&
                    (role === "Admin" || role === "Zone Admin") ? (
                      <>
                        <HStack space={"8"} width={"100%"}>
                          {!data?.response_id && (
                            <Button
                              type="primary"
                              onClick={handleBridgePush}
                              loading={loading}
                            >
                              Push to Bridge
                            </Button>
                          )}

                          {/* additional Sheet to bridge feature */}
                          {response_list?.status === "processed" &&
                            !CBS_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={() =>
                                  push_additional_data_to_bridge("Dev")
                                }
                                loading={push_additional_data_to_bridge_loading}
                              >
                                Push Additional Data to bridge
                              </Button>
                            )}

                          {data?.response_id && (
                            <Button
                              type="primary"
                              onClick={handleCheckStatus}
                              loading={get_bridge_response_loading}
                            >
                              Check bridge Status
                            </Button>
                          )}

                          {response_list?.status === "processed" &&
                            !CBS_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={handleCBSPush}
                                loading={cbs_loading}
                              >
                                Push to CBS
                              </Button>
                            )}

                          {(response_list?.status === "processed" ||
                            response_list?.status === "processed_with_error") &&
                            !CBS_response_list?.id &&
                            (role === "Admin" ||  role === "Zone Admin") && (
                              <Button
                                type="primary"
                                onClick={handleDeleteBridge}
                                loading={delete_bridge_loading}
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#ff0000",
                                }}
                              >
                                Delete From Bridge
                              </Button>
                            )}

                          {data?.response_id && CBS_response_list?.id && (
                            <Button
                              type="primary"
                              onClick={handleCBSCheckStatus}
                              loading={CBS_response_list_loading}
                            >
                              Check CBS Status
                            </Button>
                          )}

                          {(role === "Admin") &&
                            data?.response_id &&
                            CBS_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={handleDeleteCBS}
                                loading={delete_CBS_loading}
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#ff0000",
                                }}
                              >
                                Delete From CBS
                              </Button>
                            )}
                        </HStack>
                        <HStack space={"4"} marginTop={"10px"} width={"100%"}>
                          {(role === "Admin"|| role === "Zone Admin") &&
                            data?.response_id &&
                            CBS_response_list?.id &&
                            !data?.live_response_id && (
                              <Button
                                type="primary"
                                onClick={pushToBridgeLive}
                                loading={push_to_bridge_live_loading}
                              >
                                Push to Bridge Live
                              </Button>
                            )}

                          {data?.live_response_id && (
                            <Button
                              type="primary"
                              onClick={handleBridgeToPUshCheckLiveStatus}
                              loading={
                                push_bridge_to_live_response_list_loading
                              }
                            >
                              Check bridge to Live Status
                            </Button>
                          )}

                          {/* additional Sheet to bridge feature */}
                          {
                            push_bridge_to_live_response_list?.status ===
                              "processed" && (
                              // !CBS_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={() => {
                                  if (
                                    additional_excel_log_response_data?.status ===
                                    "Success"
                                  ) {
                                    set_push_additional_data_to_bridge_modal_open(
                                      true
                                    );
                                  } else {
                                    pushAdditionalDataToBridge("Live");
                                  }
                                }}
                                loading={push_additional_data_to_bridge_loading}
                              >
                                {additional_excel_log_response_data?.status ===
                                "Success"
                                  ? "Check Additional Data To Bridge Status"
                                  : "Push Additional Data to bridge"}
                              </Button>
                            )
                            // )
                          }

                          {push_bridge_to_live_response_list?.status ===
                            "processed" &&
                            additional_excel_log_response_data?.status ===
                              "Success" &&
                            !push_cbs_to_live_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={PushToCBSLive}
                                loading={push_to_cbs_live_loading}
                              >
                                Push to CBS Live
                              </Button>
                            )}
                        </HStack>
                        <HStack margin={2} space={4}>
                          {data?.live_response_id &&
                            push_cbs_to_live_response_list?.id && (
                              <Button
                                type="primary"
                                onClick={handleCBStoLiveCheckStatus}
                                loading={CBS_response_list_loading}
                              >
                                Check CBS Live Status
                              </Button>
                            )}

                          {push_cbs_to_live_response_list?.society_id && (
                            <Button
                              type="primary"
                              onClick={handle_credential_details}
                              // loading={}
                            >
                              Show Credentials
                            </Button>
                          )}
                        </HStack>
                        <HStack space={3} my={2}>
                          {push_cbs_to_live_response_list?.society_id && !is_health_checked && (
                            <Button
                              type="primary"
                              onClick={() =>
                                handle_health_check_interest("health_check")
                              }
                            >
                              Health Check
                            </Button>
                          )}
                          {push_cbs_to_live_response_list?.society_id && (
                            <Button
                              type="primary"
                              onClick={() =>
                                set_send_live_credential_modal_open(true)
                              }
                            >
                              Send Live Credential
                            </Button>
                          )}
                          {is_health_checked && !is_interests_calculated && (
                            <Button
                              type="primary"
                              onClick={() =>
                                handle_health_check_interest(
                                  "calculate_interest"
                                )
                              }
                            >
                              Calculate Interest
                            </Button>
                          )}
                          <ShowVerificationCertificate id={id} />
                        </HStack>
                      </>
                    ) : (
                      ""
                    )}
                  </Center>
                </Box>
              </HStack>
            </Card>
          </Box>

          <VStack>
            {status === "Sheet Error"
              ? errors?.map((x, i) => (
                  <LazyLoad height={140} offset={1200} key={i}>
                    <div key={i}>
                      <Box padding="3%">
                        <HStack>
                          <Box width="30%">
                            <Text bold>{t("table:error_type")} : </Text>
                          </Box>
                          <Box>
                            <Text>{x.error_type} </Text>
                          </Box>
                        </HStack>
                        {x.is_data_error || x.is_column_error ? (
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:sheet_name")} : </Text>
                            </Box>
                            <Box>
                              <Text>{x.sheet_name} </Text>
                            </Box>
                          </HStack>
                        ) : (
                          ""
                        )}
                        {x.is_data_error ? (
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:row_number")} : </Text>
                            </Box>
                            <Box>
                              <Text>{x.row_number} </Text>
                            </Box>
                          </HStack>
                        ) : (
                          ""
                        )}
                        {x.is_data_error || x.is_column_error ? (
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:column_name")} : </Text>
                            </Box>
                            <Box>
                              <Text>{x.column_name} </Text>
                            </Box>
                          </HStack>
                        ) : (
                          ""
                        )}
                        <HStack>
                          <Box width="30%">
                            <Text bold>{t("table:error_message")} : </Text>
                          </Box>
                          <Box flex={1}>
                            <Text>{x.error}</Text>
                          </Box>
                        </HStack>
                      </Box>
                    </div>
                  </LazyLoad>
                ))
              : status === "Data Error"
              ? Object.entries(errors)?.map(([sheet_name, rows]) => (
                  <div key={sheet_name}>
                    {Object.entries(rows)?.map(([row_number, errors]) => (
                      <div key={row_number}>
                        <div className="sticky-name">
                          <Center>
                            <Text bold>{sheet_name} </Text>
                          </Center>
                          <Center>
                            <Text bold>Row Number: {row_number} </Text>
                          </Center>
                        </div>
                        {errors?.map((err, i) => (
                          <LazyLoad
                            // style={{ marginTop: "30px" }}
                            height={175}
                            offset={1200}
                            key={i}
                          >
                            <div style={{ marginTop: "20px" }}>
                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:column_name")} : </Text>
                                </Box>
                                <Box>
                                  <Text>{err.column_name} </Text>
                                </Box>
                              </HStack>
                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:error_type")} : </Text>
                                </Box>
                                <Box>
                                  <Text>{err.error_type} </Text>
                                </Box>
                              </HStack>

                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:error_message")} :</Text>
                                </Box>
                                <Box flex={1} style={{ height: "90px" }}>
                                  <Text>{err.error}</Text>
                                </Box>
                              </HStack>
                            </div>
                          </LazyLoad>
                        ))}
                      </div>
                    ))}
                  </div>
                ))
              : status === "Column Error"
              ? Object.entries(errors)?.map(([sheet_name, errors]) => (
                  <div key={sheet_name}>
                    <div className="sticky-name">
                      <Center>
                        <Text bold>{sheet_name} </Text>
                      </Center>
                    </div>
                    {errors?.map((err, i) => (
                      <LazyLoad height={100} offset={1200} key={i}>
                        <div>
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:column_name")} : </Text>
                            </Box>
                            <Box>
                              <Text>{err.column_name} </Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:error_type")} : </Text>
                            </Box>
                            <Box>
                              <Text>{err.error_type} </Text>
                            </Box>
                          </HStack>

                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:error_message")} :</Text>
                            </Box>
                            <Box>
                              <Text>{err.error}</Text>
                            </Box>
                          </HStack>
                        </div>
                      </LazyLoad>
                    ))}
                  </div>
                ))
              : ""}
          </VStack>
        </Box>
      )}

      <AntdModal
        isOpen={bridge_success_modal_open}
        onClose={handleBridgeModalClose}
        width={400}
        component={<ResponseBridgeStatus handleModalClose={handleModalClose} />}
      />

      <AntdModal
        isOpen={bridge_live_success_modal_open}
        onClose={handleBridgeLiveModalClose}
        width={400}
        component={<BridgeLiveStatus handleModalClose={handleLiveModalClose} />}
      />

      <AntdModal
        isOpen={bridge_response_log_modal_open}
        onClose={handleBridgeResponseModalClose}
        width={500}
        header={"Logs & Errors"}
        component={<BridgePushResponseDetails />}
      />

      <AntdModal
        isOpen={CBS_response_log_modal_open}
        onClose={handleCBSResponseOnClose}
        width={500}
        header={"Logs"}
        component={<CbsPushResponseDetails />}
      />

      <AntdModal
        isOpen={bridge_to_live_response_log_modal_open}
        onClose={handleBRidgeToLiveResponseOnClose}
        width={500}
        header={"Logs & Errors"}
        component={<PushBridgeToLiveResponseDetails />}
      />

      <AntdModal
        isOpen={push_cbs_live_success_modal_open}
        onClose={handleCBSToLiveResponseOnClose}
        width={500}
        header={"Logs"}
        component={<PushCBStoLiveResponseDetails />}
      />

      <AntdModal
        isOpen={push_additional_data_to_bridge_modal_open}
        onClose={() => set_push_additional_data_to_bridge_modal_open(false)}
        width={500}
        header={"Logs"}
        component={<PushAdditionalDataToBridgeDetails />}
      />

      <AntdModal
        isOpen={credential_modal_open}
        onClose={() => {
          set_credential_modal_open(false);
        }}
        width={400}
        component={
          <CredentialsDetails handleModalClose={handleCredentialsModalClose} />
        }
      />

      <AntdModal
        isOpen={interest_calculate_modal_open}
        onClose={() => {
          set_interest_calculate_modal_open(false);
        }}
        width={400}
        component={
          <HealthCheckAndInterestModalDetail
            handle_modal_close={set_interest_calculate_modal_open}
            type={"calculate_interest"}
          />
        }
      />

      <AntdModal
        isOpen={health_check_modal_open}
        onClose={() => {
          set_health_check_modal_open(false);
        }}
        width={400}
        component={
          <HealthCheckAndInterestModalDetail
            handle_modal_close={set_health_check_modal_open}
            type={"health_check"}
          />
        }
      />

      <AntdModal
        isOpen={send_live_credential_modal_open}
        onClose={() => {
          set_send_live_credential_modal_open(false);
        }}
        width={400}
        component={
          <SendLiveCredentialsDetail
            handle_modal_close={set_send_live_credential_modal_open}
          />
        }
      />
    </Box>
  );
};
export default ResponseDetails;
