import {
  dynamicRequest,
  get_verification_certificate_query,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const ShowVerificationCertificate = ({ id }) => {
  const dispatch = useDispatch();
  const { file_url } = useDynamicSelector("getVerificationCertificate");

  useEffect(() => {
    get_verification();
  }, []);

  const get_verification = () => {
    let key = [{ key: "getVerificationCertificate", loading: true }];
    let variables = {
      id,
    };
    dispatch(
      dynamicRequest(key, get_verification_certificate_query, variables)
    );
  };

  const show_verification_certificate = () => {
    debugger;
    window.open(file_url);
  };

  return (
    file_url && (
      <Button type="primary" onClick={show_verification_certificate}>
        Show Verification Certificate
      </Button>
    )
  );
};

export default ShowVerificationCertificate;
