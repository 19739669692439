import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import {
  calculate_interest_mutation,
  dynamicClear,
  dynamicRequest,
  health_check_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";

const HealthCheckAndInterestModalDetail = (props) => {
  const { handle_modal_close, type } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id, society_id } = useParams();

  const [password, set_password] = useState(null);

  const {
    status: calculate_interest_status,
    error: calculate_interest_error,
    loading: calculate_interest_loading,
  } = useDynamicSelector("calculateInterests");

  const {
    status: health_check_status,
    error: health_check_error,
    loading: health_check_loading,
  } = useDynamicSelector("healthCheck");

  useEffect(() => {
    if (health_check_status === "Success" && type === "health_check") {
      showToast({ type: "success", message: "Health Status Success" });
      dispatch(dynamicClear("healthCheck"));
      handle_modal_close(false);
    } else if (health_check_error && type === "health_check") {
      showToast({
        type: "error",
        message: health_check_error?.message,
      });
      dispatch(dynamicClear("healthCheck"));
    } else if (
      calculate_interest_status === "Success" &&
      type === "calculate_interest"
    ) {
      showToast({
        type: "success",
        message: "Calculate Interest Status Success",
      });
      dispatch(dynamicClear("calculateInterests"));
      handle_modal_close(false);
    } else if (calculate_interest_error && type === "calculate_interest") {
      showToast({ type: "error", message: calculate_interest_error?.message });
      dispatch(dynamicClear("calculateInterests"));
    }

    return () => {
      form.resetFields();
    };
  }, [
    health_check_status,
    health_check_error,
    calculate_interest_status,
    calculate_interest_error,
    type,
  ]);

  const on_finish = ({ password }) => {
    if (type === "health_check") {
      let keys = [{ key: "healthCheck", loading: true }];
      let variable = {
        id: id,
        password,
      };
      dispatch(dynamicRequest(keys, health_check_mutation, variable));
    } else if (type === "calculate_interest") {
      let keys = [{ key: "calculateInterests", loading: true }];
      let variable = {
        id: id,
        password,
      };
      dispatch(dynamicRequest(keys, calculate_interest_mutation, variable));
    }
  };

  const handlePasswordChange = (e) => {
    set_password(e.target.value);
  };

  return (
    <div className="health_check_detail">
      <h3 className="password_header">
        {type === "health_check" ? "Health Check" : "Calculate Interest"}
      </h3>

      <Form
        name="health_check_form"
        layout="vertical"
        onFinish={on_finish}
        className="health_check_form"
        form={form}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            className="password_input"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={calculate_interest_loading || health_check_loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default HealthCheckAndInterestModalDetail;
